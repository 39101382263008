<template>
  <div class="box">
    <div class="infos">
      <div class="titles">
        <div class="left"></div>
        <div class="right">
          <div class="pick">
            <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
          <div class="search">
            <i class="el-icon-search"></i>
            <input class="el-input__inner" type="text" autocomplete="off" placeholder="输入账号/手机号搜索" />
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
        <el-table-column prop="agent" label="代理商" align="center" width="180"></el-table-column>
        <el-table-column prop="record" label="操作记录"></el-table-column>
        <el-table-column prop="people" label="操作人员" width="180"></el-table-column>
        <el-table-column prop="time" label="时间" width="180"></el-table-column>
      </el-table>
      <div class="bottom">
        <el-pagination :page-sizes="[10, 20, 30, 40]" :page-size="10" :pager-count="4" layout="total, sizes, prev, pager, next, jumper" :total="400"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  components: {},
  data() {
    return {
      date: ''
      // list: [
      //   {
      //     agent: 'vx',
      //     record: '管理员[admin]为代理商[茅龙]增加充值1000元,操作前余额为:1000000.00, 操作后余额为1001000.00元',
      //     people: '第二世界',
      //     time: '2023-01-20'
      //   }
      // ]
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead {
  th.el-table__cell {
    background: #ecf1f6;
  }
  color: #20303f !important;
}
::v-deep tbody {
  tr:nth-child(2n) {
    background: #fafbfc;
  }
}
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f4f6f8;
  .infos {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    .titles {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
      }
      .right {
        display: flex;
        align-items: center;
        .pick {
          width: 236px;
          height: 36px;
          border-radius: 4px;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          margin-right: 10px;
          display: flex;
          .el-input__inner {
            width: 100%;
            height: 36px;
            color: #606266;
            border: none;
          }
          ::v-deep .el-date-editor .el-range-separator {
            width: 10%;
          }
          ::v-deep .el-range-input {
            flex: 1;
          }
          ::v-deep .el-date-editor .el-range__close-icon {
            display: none;
          }
        }
        .search {
          width: 200px;
          height: 36px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding: 1px 11px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          i {
            display: inline-flex;
            white-space: nowrap;
            flex-shrink: 0;
            flex-wrap: nowrap;
            text-align: center;
            color: #a8abb2;
            transition: all 0.3;
            pointer-events: none;
            margin-right: 8px;
          }
          .el-input__inner {
            width: 100%;
            height: 36px;
            flex-grow: 1;
            color: #606266;
            padding: 0;
            border: none;
          }
        }
      }
    }
    .el-table {
      border-radius: 5px;
      box-shadow: 0 4px 14px 1px rgb(54 124 253 / 8%);
      .el-table__header-wrapper {
        border-radius: 5px 5px 0 0;
      }
      font-size: 14px;
      font-weight: 400;
      color: #6b7c8c;
    }
    .bottom {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}
</style>
